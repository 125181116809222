import React, { Component } from 'react';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Hero from '../components/we-can/Hero';
import get from 'lodash/get';
import IntroBlock from "../components/we-can/Intro";
import PrivateRoute from "../router/PrivateRoute"

class WeCanPage extends Component {
  render() {
    const path = this.props.location.pathname
    const data = get(this, 'props.data');
    const {
      page,
      introImageFirst,
      introImageSecond,
      introImageThird
    } = data;

    return (
      <Layout>
        <SEO title={page.metaTitle}
             description={page.metaDescription}
             fullTitle={false}
             path={path}/>
        <Hero/>
        <IntroBlock introImageFirst={introImageFirst} introImageSecond={introImageSecond} introImageThird={introImageThird}/>
      </Layout>
    )
  }
}

function wecan(props) {
  return <PrivateRoute component={WeCanPage} {...props}/>
}

export default wecan;


export const pageQuery = graphql`
  query WeCanPageQuery {
    page: contentfulLandingPage(slug: { eq: "we-can" }) {
      id
      metaTitle
      metaDescription
    }
    introImageFirst: file(relativePath: { eq: "we-can/intro-image-1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1106) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImageSecond: file(relativePath: { eq: "we-can/intro-image-opt-02.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 854) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImageThird: file(relativePath: { eq: "we-can/intro-image-3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 918) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
