import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import { Row, Col, Container } from "styled-bootstrap-grid";
import { Fade } from "react-reveal";
import Img from "gatsby-image";
import BlockBackgroundImg from "../../resources/images/we-can/intro-background.jpg";
import BlockBackgroundImgMobile from "../../resources/images/we-can/intro-background-mobile.jpg";
import BottomBackgroundImg from "../../resources/images/we-can/bottom-backgound.jpg";
import BottomBackgroundImgMobile from "../../resources/images/we-can/bottom-background-mobile.jpg";
import BlueLineImg from "../../resources/images/blue-line.svg";
import DecorationLetterLImg from "../../resources/images/we-can/decoration-letter-L.svg";
import DecorationLetterAImg from "../../resources/images/we-can/decoration-letter-A.svg";
import DecorationLetterSImg from "../../resources/images/we-can/decoration-letter-S.svg";
import VerticalLineImg from "../../resources/images/we-can/vertical-dash-line.svg";
import HorizontalLineImg from "../../resources/images/we-can/horizontal-dash-line.svg";
import ShortVerticalLineImg from "../../resources/images/we-can/short-vertical-dash-line.svg";
import LongHorizontalLineImg from "../../resources/images/we-can/long-horizontal-dash-line.svg";
import BottomBlock from "./BottomSection";
import DecorationTitle from "./DecorationTitle";

const WeCanBackground = styled.div`
    width: 100%;
    background: #020913;
    padding-left: 0;
    padding-right: 0;
`;

const IntroBackground = styled.div`
    background: url(${BlockBackgroundImgMobile}) no-repeat;
    z-index: 0;
    height: 859px;
    margin-top: -2050px;
    padding-bottom: 1200px;
    background-size: contain;
    @media(min-width: 768px) {
        height: 729px;
        margin-top: -2250px;
        padding-bottom: 1470px;
        background: url(${BlockBackgroundImg}) no-repeat;
        background-position: 50%;
        background-size: contain;
    }
`;

const BottomBackground = styled.div`
    background: url(${BottomBackgroundImgMobile}) no-repeat;
    z-index: 0;
    height: 859px;
    margin-top: -1600px;
    padding-bottom: 800px;
    background-size: contain;
    @media(min-width: 768px) {
        height: 621px;
        margin-top: -1000px;
        padding-bottom: 350px;
        background: url(${BottomBackgroundImg}) no-repeat;
        background-position: 50%;
        background-size: contain;
    }
`;

const ContentContainer = styled(Container)`
    padding: 40px 0 0 0;
    @media(min-width: 768px){
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 32px;
    padding-right: 33px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const RowStyledRight = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
`;

const BlueLine = styled.img`
    width: 80px;
    height: auto;
    padding-bottom: 72px;
    padding-left: 33px;
    @media(min-width: 992px){
        padding-left: 158px;
        padding-bottom: 113px;
    }
    
`;

const IntroImageFirst = styled(Img)`
    height: auto;
    max-width: 350px;
    @media(min-width: 992px){
        max-width: 553px;
    }
`;

const DecorationLetterL = styled.img`
    height: 82px;
    width: auto;
    position: absolute;
    top: -40px;
    left: 236px;
    @media(min-width: 992px){
        left: 86%;
        top: 226px;
        height: 102px;
    }
`;

const DecorationLetterA = styled.img`
    height: 82px;
    width: auto;
    position: absolute;
    top: 37%;
    right: 275px;
    @media(min-width: 992px){
        top: 87%;
        left: 49%;
        height: 102px;
    }
    @media(max-width: 378px){
        right: 255px;
    }
`;

const DecorationLetterS = styled.img`
    height: 82px;
    width: auto;
    position: absolute;
    top: 20%;
    left: 240px;
    @media(min-width: 992px){
        left: 3%;
        top: 27%;
        height: 107px;
    }
    @media(max-width: 378px){
        left: 215px;
    }
`;

const Title = styled.div`
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-top: 51px;
    padding-left: 46px;
    width: 267px;
    @media(min-width: 992px){
        padding-left: 93px;
        padding-top: 63px;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
        width: auto;
    }
`;

const SecondTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-top: 50px;
    padding-left: 78px;
    padding-bottom: 25px;
    max-width: 398px;
    @media(min-width: 992px){
        width: 557px;
        padding-left: 200px;
        padding-top: 184px;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
        padding-bottom: 31px;
    }
`;

const ThirdTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-top: 51px;
    padding-left: 46px;
    @media(min-width: 992px){
        padding-left: 112px;
        padding-top: 197px;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
    }
`;

const VerticalLine = styled.img`
    position: absolute;
    top: -72px;
    height: 167px;
    left: 25px;
    @media(min-width: 465px){
       right: -72px;
       top: -95px;
    }
    @media(min-width: 992px){
        display: none;
    }
`;

const SecondVerticalLine = styled.img`
    position: absolute;
    top: -72px;
    height: 167px;
    right: 73px;
    @media(min-width: 465px){
       right: 15%;
    }
    @media(min-width: 992px){
        display: none;
    }
`;

const ShortVerticalLine = styled.img`
    position: absolute;
    top: -50px;
    height: 147px;
    left: 5%;
    @media(min-width: 465px){
       top: -76px;
    }
    @media(min-width: 992px){
        display: none;
    }
`;

const TextBox = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #9CA8B5;
    line-height: 21px;
    padding-top: 21px;
    @media(min-width: 992px){
        padding-top: 31px;
        padding-left: 200px;
    }
`;

const SecondTextBox = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #9CA8B5;
    line-height: 21px;
    margin-bottom: 90px;
    padding-left: 33px;
    padding-right: 35px;
    @media(min-width: 992px){
           padding-left: 109px;
           max-width: 363px;
    }
`;

const ThirdTextBox = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #9CA8B5;
    line-height: 21px;
    padding-top: 21px;
    @media(min-width: 992px){
        padding-top: 31px;
        padding-left: 207px;
    }
`;

const HorizontalLine = styled.img`
    position: absolute;
    left: -32%;
    top: 136px;
    margin-right: 23.5px;
    @media(max-width: 992px){
        display: none;
    }
    @media(min-width: 992px) and (max-width: 1199px){
        top: 49%;
        left: -37%;
    }
`;

const SecondHorizontalLine = styled.img`
    position: absolute;
    width: 435px;
    top: 51%;
    left: 89%;
    @media(max-width: 992px){
        display: none;
    }
    @media(min-width: 992px) and (max-width: 1199px){
        left: 94%;
    }
`;

const ThirdHorizontalLine = styled.img`
    position: absolute;
    width: 356px;
    left: -30%;
    top: 48%;
    @media(max-width: 992px){
        display: none;
    }
    @media(min-width: 992px) and (max-width: 1199px){
        left: -35%;
    }
`;

const IntroImageSecond = styled(Img)`
    height: auto;
    margin-top: 80px;
    max-width: 337px;
    margin-left: auto;
    margin-right: 0;
        @media(min-width: 992px){
            max-width: 427px;
            margin-top: 44px;
    }
`;

const IntroImageThird = styled(Img)`
    height: auto;
    max-width: 304px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    @media(min-width: 992px){
        max-width: 459px;
        top: -49px;
        margin-right: 0;
        margin-left: auto;
    }
`;

const IntroBlock = (props) => {
  return (
    <WeCanBackground>
      <ContentContainer>
        <BlueLine src={BlueLineImg}/>
          <RowStyled>
            <ColStyled md={12} lg={6}>
              <IntroImageFirst fluid={props.introImageFirst.childImageSharp.fluid} alt={"Intro Image"}/>
              <DecorationLetterL src={DecorationLetterLImg}/>
            </ColStyled>
            <ColStyled md={12} lg={6}>
              <VerticalLine src={VerticalLineImg}/>
              <Fade bottom duration={750} delay={500}>
                <Title>
                  Better for nature
                </Title>
              </Fade>
              <HorizontalLine src={HorizontalLineImg}/>
              <Fade duration={750} delay={250}>
                <TextBox>
                  Aluminium cans are one of the most sustainable forms of packaging around. They’re energy-efficient to
                  manufacture, fuel-efficient to transport and super-simple to recycle – all of which reduce our carbon
                  footprint and mean you can feel good about enjoying our great beer.
                </TextBox>
              </Fade>
            </ColStyled>
          </RowStyled>
          <RowStyledRight>
            <ColStyled md={12} lg={6} lgOrder={2} mdOrder={1}>
              <IntroImageSecond fluid={props.introImageSecond.childImageSharp.fluid} alt={"Intro Image"}/>
              <DecorationLetterA src={DecorationLetterAImg}/>
            </ColStyled>
            <ColStyled md={12} lg={6} lgOrder={1} mdOrder={2}>
              <SecondVerticalLine src={VerticalLineImg}/>
              <Fade bottom duration={750} delay={500}>
                <SecondTitle>
                  Better for adventures
                </SecondTitle>
              </Fade>
              <SecondHorizontalLine src={LongHorizontalLineImg}/>
              <Fade duration={750} delay={250}>
                <SecondTextBox>
                  Our philosophy is all about going further – and when you’re busy taking things to the next level you
                  don’t want breakable glass in your bag. Shatterproof, stackable and easily packed, our super-practical
                  cans go where other packaging can’t – just throw a four-pack into your backpack and go.
                </SecondTextBox>
              </Fade>
            </ColStyled>
          </RowStyledRight>
          <RowStyled>
            <ColStyled md={12} lg={6}>
              <IntroImageThird fluid={props.introImageThird.childImageSharp.fluid} alt={"Intro Image"}/>
              <DecorationLetterS src={DecorationLetterSImg}/>
            </ColStyled>
            <ColStyled md={12} lg={6}>
              <ShortVerticalLine src={ShortVerticalLineImg}/>
              <Fade bottom duration={750} delay={500}>
                <ThirdTitle>
                  Better for life
                </ThirdTitle>
              </Fade>
              <ThirdHorizontalLine src={HorizontalLineImg}/>
              <Fade duration={750} delay={250}>
                <ThirdTextBox>In our opinion there are few moments in life not improved by a good beer. From forming
                friendships to celebrating success, Lost at Sea is the perfect accompaniment to life’s amazing moments,
                with a flavour that’s as refreshing as a cool sea breeze on a sweltering summer day.</ThirdTextBox>
              </Fade>
            </ColStyled>
          </RowStyled>
      </ContentContainer>
      <IntroBackground/>
      <DecorationTitle/>
      <BottomBlock/>
      <BottomBackground/>
    </WeCanBackground>
  )
}

export default IntroBlock

