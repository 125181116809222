import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Fade } from "react-reveal"


const StyledTitle = styled.h1`
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 46px;
    letter-spacing: 3px;
    color: white;
    text-align: left;
    padding-left: 24px;
    position: absolute;
    bottom: 0;
    @media(min-width: 768px) {
        font-size: 62px; 
        letter-spacing: 4px;
        padding-left: 158px;
    }
    @media(max-width: 370px) {
        font-size: 35px;
    }
`
const WhiteLine = styled.div`
    margin-bottom: 0;
`
const OutlinedLine = styled.div`
    color: transparent;
	  -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    margin-top: 0;
    margin-bottom: 0;
`

const Title = () => {
  return (
    <StyledTitle>
      <Fade duration={750} delay={500}>
        <WhiteLine>We can<br/>because</WhiteLine>
      </Fade>
      <Fade duration={750} delay={750}>
        <OutlinedLine>We care</OutlinedLine>
      </Fade>
    </StyledTitle>
  )
}

export default Title