import React from "react"
import { Zoom } from "react-reveal"
import DecorationTitleImg1 from "../../resources/images/we-can/decoration-letter-1.svg"
import DecorationTitleImg2 from "../../resources/images/we-can/decoration-letter-2.svg"
import DecorationTitleImg3 from "../../resources/images/we-can/decoration-letter-3.svg"
import DecorationTitleImg4 from "../../resources/images/we-can/decoration-letter-4.svg"
import DecorationTitleImg5 from "../../resources/images/we-can/decoration-letter-5.svg"
import DecorationTitleImg6 from "../../resources/images/we-can/decoration-letter-6.svg"
import DecorationTitleImg7 from "../../resources/images/we-can/decoration-letter-7.svg"
import DecorationTitleImg8 from "../../resources/images/we-can/decoration-letter-8.svg"
import DecorationTitleImg9 from "../../resources/images/we-can/decoration-letter-9.svg"
import DecorationTitleImg10 from "../../resources/images/we-can/decoration-letter-10.svg"
import DecorationTitleImg11 from "../../resources/images/we-can/decoration-letter-11.svg"
import DecorationTitleImg12 from "../../resources/images/we-can/decoration-letter-12.svg"
import DecorationTitleImg13 from "../../resources/images/we-can/decoration-letter-13.svg"

import styled from "styled-components"
import { Row } from "styled-bootstrap-grid"

const TitleLetter = styled.img`
    height: 41px;
    @media (min-width: 992px){
        height: 70px;
    }
    @media (min-width: 1099px){
        height: 79px;
    }
`;

const DesktopOnly = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 138px;
    margin-bottom: 150px;
    width: 100%;
    @media(max-width: 992px) {
        display:  none;
    }
`;

const MobileOnly = styled.div`
    display: block;
    width: 100%;
    margin: 100px auto 85px auto;
    @media(min-width: 992px) {
        display:  none;
    }
`;

const FirstRow = styled(Row)`
    margin: auto;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SecondRow = styled(Row)`
    margin: auto;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

const DecorationTitle = () => {
  return (
    <div>
      <DesktopOnly>
        <Zoom cascade duration={3000}>
          <TitleLetter src={DecorationTitleImg1}/>
          <TitleLetter src={DecorationTitleImg2}/>
          <TitleLetter src={DecorationTitleImg3}/>
          <TitleLetter src={DecorationTitleImg4}/>
          <TitleLetter src={DecorationTitleImg5}/>
          <TitleLetter src={DecorationTitleImg6}/>
          <TitleLetter src={DecorationTitleImg7}/>
          <TitleLetter src={DecorationTitleImg8}/>
          <TitleLetter src={DecorationTitleImg9}/>
          <TitleLetter src={DecorationTitleImg10}/>
          <TitleLetter src={DecorationTitleImg11}/>
          <TitleLetter src={DecorationTitleImg12}/>
        </Zoom>
      </DesktopOnly>
      <MobileOnly>
        <FirstRow>
          <Zoom cascade duration={3000}>
            <TitleLetter src={DecorationTitleImg1}/>
            <TitleLetter src={DecorationTitleImg2}/>
            <TitleLetter src={DecorationTitleImg3}/>
            <TitleLetter src={DecorationTitleImg4}/>
            <TitleLetter src={DecorationTitleImg5}/>
            <TitleLetter src={DecorationTitleImg6}/>
          </Zoom>
        </FirstRow>
        <SecondRow>
          <Zoom cascade duration={3000}>
            <TitleLetter src={DecorationTitleImg7}/>
            <TitleLetter src={DecorationTitleImg8}/>
            <TitleLetter src={DecorationTitleImg9}/>
            <TitleLetter src={DecorationTitleImg10}/>
            <TitleLetter src={DecorationTitleImg11}/>
            <TitleLetter src={DecorationTitleImg13}/>
          </Zoom>
        </SecondRow>
      </MobileOnly>
    </div>
  )
}

export default DecorationTitle