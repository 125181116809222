import React from 'react';
import styled from 'styled-components';
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from 'gatsby';
import ShortOverlayBottom from "../core/ShortOverlayBottom"
import Title from '../we-can/Title'
import {Container } from "styled-bootstrap-grid"

const BackgroundStyled = styled.div`
    position: relative;
    height: 420px;
    margin-top: -138px;
    text-align: center;
    @media(min-width: 768px) {
        height: 500px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: cover;
`;


const HomeHero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "we-can/hero.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
    const imageData = data.background.childImageSharp.fluid;

    return (
        <BackgroundStyled>
            <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={100}>
              <ShortOverlayBottom/>
              <Container>
                <Title/>
              </Container>
            </BackgroundImageStyled>
        </BackgroundStyled>
    )
    }
  }
  />);

export default HomeHero;
