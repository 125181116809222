import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import CanImg from "../../resources/images/we-can/can-icon.svg"
import RecycleImg from "../../resources/images/we-can/recycle-icon.svg"
import CalendarImg from "../../resources/images/we-can/calendar-icon.svg"
import ColdImg from "../../resources/images/we-can/cold-icon.svg"
import EfficientImg from "../../resources/images/we-can/efficient-icon.svg"

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;  
    padding-left: 34px;
    padding-right: 33px; 
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    @media(min-width: 992px){
    padding-left: 5px;
    padding-right: 5px;
    }
`

const BottomContainer = styled(Container)`
    display: block;
    padding: 0;
    @media(min-width: 768px){
        padding-bottom: 140px;
    }
`

const CanImgBox = styled.img`
    height: 63px;
`

const RecycleImgBox = styled.img`
    height: 66px;
`

const CalendarImgBox = styled.img`
    height: 66px;
`

const ColdImgBox = styled.img`
    height: 53px;
`

const EfficientImgBox = styled.img`
    height: 44px;
`

const BottomText = styled.div`
    ${fonts.gilroyRegular};
    color: #9CA8B5;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    padding: 35px 0 55px 0;
    max-width: 173px;
    margin: auto;
`

const IconBox = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`


const BottomBlock = () => {
  return (
      <BottomContainer>
        <RowStyled>
          <ColStyled xs={6} sm={6} md={6} lg={2} lgOffset={1}>
            <IconBox>
              <CanImgBox src={CanImg}/>
            </IconBox>
            <BottomText>
              The avergae aluminium can is made from 58% total recycled content
            </BottomText>
          </ColStyled>
          <ColStyled xs={6} sm={6} md={6} lg={2}>
            <IconBox>
              <RecycleImgBox src={RecycleImg}/>
            </IconBox>
            <BottomText>
              Aluminium cans are infinitely recyclable
            </BottomText>
          </ColStyled>
          <ColStyled xs={6} sm={6} md={6} lg={2}>
            <IconBox>
              <CalendarImgBox src={CalendarImg}/>
            </IconBox>
            <BottomText>
              Cans go from the recycling bin to store shelves within 60 days
            </BottomText>
          </ColStyled>
          <ColStyled xs={6} sm={6} md={6} lg={2}>
            <IconBox>
              <ColdImgBox src={ColdImg}/>
            </IconBox>
            <BottomText>
              Cans stay colder for longer, keeping the water cool and fresh
            </BottomText>
          </ColStyled>
          <ColStyled xs={12} sm={12} md={12} lg={2}>
            <IconBox>
              <EfficientImgBox src={EfficientImg}/>
            </IconBox>
            <BottomText>
              Cans pack tighter making them more efficient to transport
            </BottomText>
          </ColStyled>
        </RowStyled>
      </BottomContainer>

  )
}

export default BottomBlock

